import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import profilePic from "../../content/assets/cristian.jpg"

import { rhythm } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const blogPath = `${__PATH_PREFIX__}/blog/`
    let header

    if (location.pathname === rootPath || location.pathname === blogPath) {
      header = (
        <Header>
          <Title>
            Hello, I'm <strong>Cristian Echeverría</strong>
          </Title>
          <SubTitle>software developer</SubTitle>
          <IntroImg
            src={profilePic}
            alt="a picture of Cristian Echeverría smiling"
          />
        </Header>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/blog/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <Wrapper>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          <header>{header}</header>
          <main>{children}</main>
        </div>
        <Footer>
          © {new Date().getFullYear()}, Developed using
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </Footer>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
`

const Header = styled.section`
  position: relative;

  @media (min-width: 600px) {
    display: grid;
    width: min-content;
    margin: 0 auto;
    grid-column-gap: 1em;
    grid-template-areas:
      "img title"
      "img subtitle";
    grid-template-columns: min-content max-content;
  }

  & img {
    display: block;
    max-width: 100%;
  }
`

const Title = styled.h1`
  margin-bottom: 0.25em;
  font-weight: 400;

  & strong {
    display: block;
  }
`

const SubTitle = styled.p`
  margin: 0;
  font-size: 1.25rem;
  background: rgb(83, 72, 255) none repeat scroll 0% 0%;
  padding: 0.25em 1em;
  font-family: "Roboto Slab", serif;
  margin-bottom: 1em;
  color: #eeeeee;
  display: inline-block;

  @media (min-width: 600px) {
    align-self: start;
    grid-column: -1 / 1;
    grid-row: 2;
    text-align: right;
    position: relative;
    left: -1.5em;
    width: calc(100% + 1.5em);
  }
`

const IntroImg = styled.img`
  box-shadow: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25),
    0.125em 0.125em 0.25em rgba(0, 0, 0, 0.15);

  @media (min-width: 600px) {
    grid-area: img;
    min-width: 250px;
    position: relative;
    z-index: 2;
  }
`

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
`

export default Layout
